import Href from 'components/common/Href';
import ColorThemeSelect from 'components/footer/ColorThemeSelect';

import Button from 'components/common/Button';
import WalletIndicator from 'components/header/WalletIndicator';
import { useColorTheme } from 'lib/hooks/useColorTheme';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import MobileMenu from './MobileMenu';
import NavLink from './NavLink';
import SearchBar from './SearchBar';
interface Props {
  searchBar?: boolean;
}

const Header = ({ searchBar = true }: Props) => {
  const { address: account } = useAccount();
  const { darkMode } = useColorTheme();
  const [loginStatus, setLoginStatus] = useState(false);
  useEffect(() => {
    if (account) {
      setLoginStatus(true);
    } else {
      setLoginStatus(false);
    }
  }, [account]);

  return (
    <header className="flex flex-col relative p-3 sm:p-4 gap-4 bg-[#fff] dark:bg-[#191C1F] shadow-[0px_2px_1px_#ddd] dark:shadow-none">
      <div className="flex justify-between md:justify-between items-center gap-4 w-full max-w-[1400px] m-auto ">
        <div className="lg:flex justify-start items-center gap-4">
          <Href href="/" underline="none" className="flex" router>
            <Image
              src="/assets/images/logo.png"
              alt="Banditproof logo"
              height="56"
              width="63"
              className="filter shrink-0"
              priority
            />
          </Href>
          <div className="hidden lg:flex justify-start items-center gap-4 flex-wrap">
            {/* <NavLink to="https://t.me/pulsepolice" text={'Report'} external /> */}
            <NavLink to="https://www.hex.toys" text={'Boutique'} external />
            <NavLink to="https://marketplace.hex.toys/explore-collections" text={'Explore'} external />
            {loginStatus && (
              <>
                <NavLink to="https://marketplace.hex.toys/create-single" text={'Create'} external />
                <NavLink to="https://marketplace.hex.toys/import" text={'Import'} external />
                <NavLink to={`https://marketplace.hex.toys/profile/${account || ''}`} text={'My Items'} external />
              </>
            )}
          </div>
        </div>
        <div className="hidden lg:flex ">{searchBar && <SearchBar />}</div>
        <div className="hidden lg:flex md:gap-3 items-center">
          <WalletIndicator />
          <ColorThemeSelect />
          <div className="hidden xl:flex gap-3">
            <Button href={'/'} size="none" style="tertiary" router>
              <img
                src={`/assets/images/icons/${darkMode ? 'icon_message_alrt' : 'icon_message_alrt_black'}.svg`}
                alt=""
                className="transition-all duration-75 ease-in hover:drop-shadow-[3px_3px_3px_#FF4835]"
              />
            </Button>

            <Button href={'/'} size="none" style="tertiary" router>
              <img src="/assets/images/icons/Vector.png" alt="" className="transition-all duration-75 ease-in" />
            </Button>

            <Button href={'/'} size="none" style="tertiary" router>
              <img
                src={`/assets/images/icons/${darkMode ? 'icon_curt' : 'icon_curt_black'}.svg`}
                alt=""
                className="transition-all duration-75 ease-in hover:drop-shadow-[3px_3px_3px_#FF4835]"
              />
            </Button>
          </div>
        </div>
        <div className="flex lg:hidden justify-end">
          <MobileMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
