import { useMounted } from 'lib/hooks/useMounted';
import { useEffect } from 'react';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import ChainSelect from '../common/ChainSelect';
import WalletIndicatorDropdown from './WalletIndicatorDropdown';

interface Props {
  menuAlign?: 'left' | 'right';
  size?: 'sm' | 'md' | 'lg' | 'none';
  style?: 'primary' | 'secondary' | 'tertiary' | 'none';
  className?: string;
}

const WalletIndicator = ({ menuAlign, size, style, className }: Props) => {
  const isMounted = useMounted();
  const { address: account } = useAccount();
  const { switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();

  useEffect(() => {
    if (switchNetwork && chain?.id !== 369 && chain?.id !== 943) {
      switchNetwork(369);
    }
  }, [chain, switchNetwork]);

  if (!isMounted) return null;

  return (
    <div className="flex flex-col sm:flex-row gap-4 sm:gap-2">
      {account && chain && (
        <ChainSelect
          instanceId="global-chain-select"
          onSelect={switchNetwork}
          selected={chain.id}
          menuAlign={menuAlign}
          showNames
        />
      )}
      <WalletIndicatorDropdown
        size={size}
        style={style}
        className={
          'border-0 text-white rounded-full bg-gradient-to-r from-[#FF00FF] to-[#FE511B] transition-all duration-75 hover:bg-gradient-to-b hover:shadow-[0px_0px_5px_#FF00FF] '
        }
      />
    </div>
  );
};

export default WalletIndicator;
