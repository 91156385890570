import AddressSearchBox from 'components/common/AddressSearchBox';
import { useRouter } from 'next/router';
import { useState } from 'react';

const SearchBar = () => {
  const router = useRouter();
  const [value, setValue] = useState<string>('');

  return (
    <AddressSearchBox
      onSubmit={() => router.push(`https://marketplace.hex.toys/search/${value}`)}
      onChange={(ev) => setValue(ev.target.value.trim())}
      value={value}
      placeholder="Search for collections, NFTs or users"
      className="w-full max-w-3xl text-sm sm:text-sm"
    />
  );
};

export default SearchBar;
