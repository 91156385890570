import Href from 'components/common/Href';
import { DISCORD_URL, GITHUB_URL, TWITTER_URL } from 'lib/constants';
import { useColorTheme } from 'lib/hooks/useColorTheme';

const Footer = () => {
  const { darkMode } = useColorTheme();
  return (
    <footer className="w-full max-w-7xl m-auto flex flex-col gap-4 items-center justify-between py-8 px-4 lg:py-4 lg:px-8">
      <div className="w-full flex flex-col lg:flex-row md:flex-row justify-between">
        <div className="flex flex-col w-full lg:w-2/5 md:w-1/4 items-start">
          <Href href="/" router>
            {darkMode ? (
              <img src="/assets/images/hex_logo.png" alt="" className="max-w-[54px] mb-6" />
            ) : (
              <img src="/assets/images/hex_logo_black.png" alt="" className="max-w-[54px] mb-6" />
            )}
          </Href>
          <p className="text-[#7780A1] dark:text-[#8F9BB7] mb-4 text-center lg:text-left md:text-left">
            The future is in your hands
          </p>
        </div>
        <div className="flex flex-col lg:flex-row md:flex-row w-full lg:w-3/5  md:w-3/4 ">
          <div className="w-full flex flex-wrap lg:flex-nowrap md:flex-row justify-between gap-5">
            <div className="flex flex-col w-2/5 md:w-1/3 items-start gap-3">
              <h3 className="text-text-2xl text-[#7780A1] dark:text-[#8F9BB7] mb-4 text-center lg:text-left">
                Community
              </h3>
              <Href href={DISCORD_URL} underline="hover" external>
                Discord
              </Href>
              <Href href={TWITTER_URL} underline="hover" external>
                Twitter
              </Href>
              <Href href={'/'} underline="hover" external>
                Instagram
              </Href>
              <Href href={'/'} underline="hover" external>
                Telegram
              </Href>
              <Href href={GITHUB_URL} underline="hover" external>
                GitHub
              </Href>
            </div>
            <div className="flex flex-col w-2/5 md:w-1/3 items-start gap-3">
              <h3 className="text-text-2xl text-[#7780A1] dark:text-[#8F9BB7] mb-4 text-center lg:text-left">
                Info & Support
              </h3>
              <Href href="/privacy-policy" underline="hover" router>
                Privacy
              </Href>
              <Href href={'/'} underline="hover" external>
                Blog
              </Href>
              <Href href={'/'} underline="hover" external>
                Help
              </Href>
              <Href href={'/'} underline="hover" external>
                Discussion
              </Href>
            </div>
            <div className="flex flex-col w-1/2 md:w-1/3 items-start gap-3">
              <h3 className="text-text-2xl text-[#7780A1] dark:text-[#8F9BB7] mb-4 text-center lg:text-left">
                Coming Soon
              </h3>
              <Href href={'https://google.com'} underline="hover" external>
                <img src="/assets/images/icons/google.png" alt="" className="max-h-[52px] invert dark:invert-0" />
              </Href>
              <Href href={'https://www.apple.com/store'} underline="hover" external>
                <img src="/assets/images/icons/google.png" alt="" className="max-h-[52px]  invert dark:invert-0" />
              </Href>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col lg:flex-row md:flex-row justify-between gap-5">
        <div className="text-[#7780A1] dark:text-[#8F9BB7]">© BanditProof 2023</div>
        <div className="flex gap-4 shrink-0">
          <Href href={DISCORD_URL} external>
            <img
              src="/assets/images/icons/Discord.png"
              alt=""
              className="max-h-[30px] transition-all duration-75 ease-in hover:brightness-200"
            />
          </Href>

          <Href href={'/'} external>
            <img
              src="/assets/images/icons/Instagram.png"
              alt=""
              className="max-h-[30px] transition-all duration-75 ease-in hover:brightness-200"
            />
          </Href>

          <Href href={TWITTER_URL} external>
            <img
              src="/assets/images/icons/Twitter.png"
              alt=""
              className="max-h-[30px] transition-all duration-75 ease-in hover:brightness-200"
            />
          </Href>

          <Href href={'/'} external>
            <img
              src="/assets/images/icons/Telegram.png"
              alt=""
              className="max-h-[30px] transition-all duration-75 ease-in hover:brightness-200"
            />
          </Href>

          <Href href={GITHUB_URL} external>
            <img
              src="/assets/images/icons/GitHub.png"
              alt=""
              className="max-h-[30px] transition-all duration-75 ease-in hover:brightness-200"
            />
          </Href>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
